let _state = {};

let KeepState = {
  saveState: function(state, key) {
    _state[key] = state;
  },
  getState: function(key) {
    return _state[key];
  }
};    

module.exports = KeepState;