import moment from 'moment';
import queryString from 'query-string';
import { api } from '../utils';
import Swal from 'sweetalert2';

var utils = {
    getParam: function(props, name){
        return (props &&  props.match && props.match.params) ? props.match.params[name] : null;
    },
    getQuery: (props, name) => {
        const vv = props.location ? queryString.parse(props.location.search) : null;
        return vv ? vv[name] : '';
    },
    randomString : (l) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        var length = l || 10;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    safeConcatFormat: (obj, columns, separator) => {
        if(separator == null){
            separator = ' ';
        }
        var tokens = [];
        if(obj && typeof obj == 'object'){
            columns.forEach(item => {
                if(obj[item]){
                    tokens.push(obj[item]);
                }
            });
        }
        return tokens.join(separator).trim();
    },
    safeDateFormat: (strDate, strFormat) => {
        if(strDate){
            var date = moment(strDate);
            if(date && date.isValid()){
                return date.format(strFormat || 'DD/MM/YYYY');
            }
        }
        return '';
    },
    safeDateFormatHumanizado: (strDate) => {
        if(strDate){
            var date = moment(strDate);
            if(date && date.isValid()){
                var diffSec = moment().diff(date, 'seconds');
                if(diffSec < 60){
                    return 'Hace un momento';
                }

                var diffMinutos = Math.round(diffSec / 60);
                if(diffMinutos < 60){
                    return 'Hace '+diffMinutos+" min";
                }

                var diffHoras = Math.round(diffMinutos / 60);
                if(diffHoras < 24){
                    return 'Hace '+diffHoras+" horas";
                }

                var diffDias = Math.round(diffHoras / 24);
                if(diffDias < 7){
                    return 'Hace '+diffDias+' días';
                }

                var diffSemanas = Math.round(diffDias / 7);
                if(diffSemanas < 4){
                    return 'Hace '+diffSemanas+" semanas";
                }

                var diffMeses = Math.round(diffSemanas / 4);
                if(diffMeses < 12){
                    return 'Hace '+diffMeses+' meses';
                }

                var diffAnios = Math.round(diffMeses / 12);
                return 'Hace '+diffAnios+' años';
            }
        }
        return '';
    },
    getListaEstatusRentaEquipo: () => {
        return [
            {id: 'cancelado', label: 'Cancelado'},
            {id: 'incompleto', label: 'Incompleto'},
            {id: 'pendiente', label: 'Pendiente'},
            {id: 'aprobado', label: 'Aprobado'},
            {id: 'salida_parcial', label: 'Salida parcial'},
            {id: 'salida', label: 'Salida'},
            {id: 'entrada_parcial', label: 'Entrada parcial'},
            {id: 'entrada', label: 'Entrada'},
        ];
    },
    listaEstatusRentaMovimiento: [
        {id: 'cancelado', label: 'Cancelado'},
        {id: 'pendiente', label: 'Pendiente de revisión'},
        {id: 'proceso', label: 'En proceso'},
        {id: 'finalizado', label: 'Finalizado'},
    ],
    getListaEstatusRenta: () => {
        return [
            { id: 'cancelado', label: 'Cancelado' },
            { id: 'incompleto', label: 'Incompleto' },
            { id: 'pendiente', label: 'Pendiente de aprobación' },
            { id: 'proceso_entrega', label: 'En proceso de entrega' },
            { id: 'salida', label: 'Salida (En renta)' },
            // { id: 'salida', label: 'Renta activa (salida)' },
            { id: 'entrada', label: 'Entrada (Renta finalizada)' },
        ]
    },
    getListaTipoMovimientoBodega: () => {
        return [
            { id: 'salida_ajuste_fisico', label: 'Salida por ajuste al físico' },
            { id: 'entrada_activo', label: 'Entrada de activo' },
            { id: 'venta_activo', label: 'Venta de Activo' },
            { id: 'baja_activo', label: 'Baja de activo' },
            { id: 'traslado_bodega', label: 'Traslado entre bodegas' },
            { id: 'entrada_ajuste_fisico', label: 'Entrada por ajuste al físico' },
        ];
    },
    getURL : ( value ) => {
        var url = '';
        if( value ){
            if(value instanceof File || value instanceof Blob){
                url = URL.createObjectURL(value);
            }else{
                url = api.baseURLImagen + ( (value.charAt(0) === '/' || value.charAt(0) === '\\') ? value : ('/'+value));
            }
        }
        return url;
    },
    isValidFormikArrayField: (formik, index, array_name, field_name) => {
        // valid={formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && (!formik.errors.requisitos || !formik.errors.requisitos[index] || !formik.errors.requisitos[index].cantidad_solicitada)}
        // invalid={(formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && formik.errors.requisitos && formik.errors.requisitos[index] && formik.errors.requisitos[index].cantidad_solicitada) ? true : false}
        if (array_name) {
            return formik.touched[array_name] &&
                formik.touched[array_name][index] &&
                formik.touched[array_name][index][field_name] &&
                (
                    !formik.errors[array_name] ||
                    !formik.errors[array_name][index] ||
                    !formik.errors[array_name][index][field_name]
                );
        } else {
            return !formik.errors[field_name] && formik.touched[field_name];
        }
    },
    isInvalidFormikArrayField: (formik, index, array_name, field_name) => {
        if (array_name) {
            return (
                formik.touched[array_name] &&
                formik.touched[array_name][index] &&
                formik.touched[array_name][index][field_name] &&
                formik.errors[array_name] &&
                formik.errors[array_name][index] &&
                formik.errors[array_name][index][field_name]
            ) ? true : false;
        } else {
            return formik.errors[field_name] && formik.touched[field_name];
        }
    },
    getErrorFormikFieldArray: (formik, index, array_name, field_name) => {
        if (utils.isInvalidFormikArrayField(formik, index, array_name, field_name)) {
            return array_name ? formik.errors[array_name][index][field_name] : formik.errors[field_name];
        } else {
            return '';
        }
    },
    substring3Points: (str, maxLength) => {
        if(str && str.length > maxLength){
            return str.substring(0, maxLength)+"...";
        }
        return str || '';
    },
    onKeyDown: (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
    },
    isFileImage: (file) => {
        let extension = (file ? file.split('.').pop() : '').toLowerCase()
        return ['jpg','jpeg', 'png', 'gif'].indexOf(extension) !== -1
    },
    isFilePdf: (file) => {
        let extension = (file ? file.split('.').pop() : '').toLowerCase()
        return ['pdf'].indexOf(extension) !== -1
    },
    listaEstatusBodegaEquipoMovimiento: [
        { id: 'salida_ajuste_fisico', label: 'Baja por ajuste al físico' },
        { id: 'entrada_activo', label: 'Entrada de activo' },
        { id: 'venta_activo', label: 'Venta de activo' },
        { id: 'baja_activo', label: 'Baja de activo' },
        { id: 'traslado_bodega', label: 'Traslado entre bodegas' },
        { id: 'entrada_ajuste_fisico', label: 'Alta por ajuste al físico' },
    ],
    getEstatusFormaBodegaEquipoMovimiento: (id) => {
        let lista = [
            { id: 'salida_ajuste_fisico', label: 'Baja por ajuste al físico' },
            { id: 'entrada_activo', label: 'Entrada de activo' },
            { id: 'venta_activo', label: 'Venta de activo' },
            { id: 'baja_activo', label: 'Baja de activo' },
            { id: 'traslado_bodega', label: 'Traslado entre bodegas' },
            { id: 'entrada_ajuste_fisico', label: 'Alta por ajuste al físico' },
            { id: 'renta', label: 'Renta' },
            { id: 'renta_dev', label: 'Devolución por Renta' },
            { id: 'entrada_mtto', label: 'Entrada por Mantenimiento' },
            { id: 'salida_mtto', label: 'Salida por Mantenimiento' },
            { id: 'traslado_bodega_entrada', label: 'Entrada por Traslado entre bodegas' },
            { id: 'traslado_bodega_salida', label: 'Salida por Traslado entre bodegas' },
        ]

        let found = lista.find( item => item.id == id)

        if( found ) return found.label

        return id || ''
    },
    dialogConfirm: (title, text, confirmButtonText, cancelButtonText) => {
        return Swal.fire({
            title: title || 'Confirmación',
            text: text,
            reverseButtons: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: confirmButtonText || 'Aceptar',
            cancelButtonText: cancelButtonText || 'Cancelar',
            icon: 'question'
        })
    },
    dialogInfo: (title, text, closeButtonText) => {
        return Swal.fire({
            title: title || 'Información',
            text: text,
            confirmButtonText: closeButtonText || 'Aceptar',
            icon: 'info'
        })
    },
    dialogError: (title, text) => {
        return Swal.fire({
            title: title || 'Error',
            text: text,
            icon: 'error'
        })
    },
    dialogSuccess: (title, text) => {
        
        return Swal.fire({
            title: title || 'Exitoso',
            text: text,
            icon: 'success'
        })

    },
    dateFormatPart: (str, part, longFormat) => {
        if(str){
            var date = moment(str);
            if(date && date.isValid()){
                if(longFormat){
                    if(part == 'month'){
                        var mm = date.get('month');
                        switch(mm){
                            case 0:
                                return 'Enero';
                            case 1:
                                return 'Febrero';
                            case 2:
                                return 'Marzo';
                            case 3:
                                return 'Abril';
                            case 4:
                                return 'Mayo';
                            case 5:
                                return 'Junio';
                            case 6:
                                return 'Julio';
                            case 7:
                                return 'Agosto';
                            case 8:
                                return 'Septiembre';
                            case 9:
                                return 'Octubre';
                            case 10:
                                return 'Noviembre';
                            case 11:
                                return 'Diciembre';
                            default:
                                return '--';
                        }
                    }else{
                        return '--';
                    }
                }else{
                    return date.get(part);
                }
            }
        }
        return '';
    },
    listaEstatusEquipoMovimiento: [
        { id: 'salida_ajuste_fisico', label: 'Baja por ajuste al físico' },
        { id: 'entrada_activo', label: 'Entrada de activo' },
        { id: 'venta_activo', label: 'Venta de activo' },
        { id: 'baja_activo', label: 'Baja de activo' },
        { id: 'traslado_bodega_entrada', label: 'Entrada por Traslado entre bodegas' },
        { id: 'traslado_bodega_salida', label: 'Salida por Traslado entre bodegas' },
        { id: 'entrada_ajuste_fisico', label: 'Alta por ajuste al físico' },
        { id: 'renta', label: 'Renta' },
        { id: 'renta_dev', label: 'Devolución por Renta' },
        { id: 'entrada_mtto', label: 'Entrada por Mantenimiento' },
        { id: 'salida_mtto', label: 'Salida por Mantenimiento' },
    ],
    listaEstatusEntrega: [
        { id: 'pendiente', labelSalida: 'Pendiente de entrega', labelEntrada: 'Pendiente de recolección', bgColor: '#dadada', txtColor: '#1e1e1e' },
        { id: 'en_camino', labelSalida: 'Entrega en camino', labelEntrada: 'Recolección en camino', bgColor: '#3399ff', txtColor: 'white' },
        { id: 'entrega_iniciada', labelSalida: 'Entrega en proceso', labelEntrada: 'Recolección en proceso', bgColor: '#194c7f', txtColor: 'white' },
        { id: 'entrega_finalizada', labelSalida: 'Entrega finalizada', labelEntrada: 'Recolección finalizada', bgColor: '#0ba552', txtColor: 'white' },
    ],
    estatusEntregaFormal: (estatus, accion) => {
        let val = utils.listaEstatusEntrega.find(estatusEntregaObj => estatusEntregaObj.id == estatus);
        return val ? (accion == 'salida'  ? val.labelSalida : val.labelEntrada) : 'Desconocido';
    },
    colorEstatusEntrega: (estatus) => {
        let val = utils.listaEstatusEntrega.find(estatusEntregaObj => estatusEntregaObj.id == estatus);
        return val ? [val.bgColor, val.txtColor] : ['grey', 'black'];
    },
    tipoComprobanteFormal: (tipo ) => {
        switch(tipo){
            case 'factura': return 'Factura';
            case 'comprobante_pago': return 'Comprobante de pago';
            case 'comprobante_nomina': return 'Comprobante de nómina';
            default: return 'Desconocido';
        }
    },
    estatusComprobanteFormal: (estatus) => {
        switch(estatus){
            case 'activo': return 'Activo';
            case 'cancelado': return 'Cancelado';
            default: return 'Desconocido';
        }
    },
    tipoBgTaskFormal: (identificador) => {
        switch(identificador){
            case 'SYNC_DAILY_COMPROBANTES': return 'Descarga de comprobantes fiscales';
            default: return 'Desconocido';
        }
    },
    estatusBgTaskForma: (status) => {
        switch(status){
            case 'pending': return 'Ejecutando...';
            case 'success': return 'Exitoso';
            case 'failed': return 'Fallido';
            default: return 'Desconocido';
        }
    }
};

export default utils;