import axios, { post, put } from 'axios';
import queryString from 'query-string';
import {session} from './';
import moment from 'moment';
import { toast } from 'react-toastify';

const baseURLImagen   = process.env.REACT_APP_BASE_URL_IMAGEN
const baseURL         = process.env.REACT_APP_BASE_URL
const baseURLCatalogo = process.env.REACT_APP_BASE_URL_CATALOGO

const manejarVerificacionError = (error) => {
	if(error && error.response && error.response.status == 401){
		var data = error.response.data;
		toast.error(data.meta.message || 'Su sesión ha expirado por seguridad');
		session.clear();
		session.setFlashValue('error_logout_message', data.meta.message);
		window.location.reload();
	}
};

const api = axios.create({
  baseURL: baseURL,
});

const api_no_bloqueante = axios.create({
	baseURL: baseURL,
});

const api_catalogo = axios.create({
	baseURL: baseURLCatalogo,
});

function getHeaders(){
	return {
		'Authorization': "Bearer "+session.get('token'),
		'app': 'admin',
	};
}

api.interceptors.request.use(function (config) {
	
	var hh = getHeaders();
	for(var k in hh){
		if(hh[k] != null){
			config.headers[k] = hh[k];
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

api_catalogo.interceptors.request.use(function (config) {
	
	var hh = getHeaders();
	for(var k in hh){
		if(hh[k] != null){
			config.headers[k] = hh[k];
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

api_no_bloqueante.interceptors.request.use(function (config) {
	
	var hh = getHeaders();
	for(var k in hh){
		if(hh[k] != null){
			config.headers[k] = hh[k];
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

const createQueryString = (params, prefix, includeNulls) => {
	console.log("====> llamando para "+prefix);
	var items = [];
	for(const key in params){
		if(params.hasOwnProperty(key)){
			var val = params[key];
			var type = typeof val;
			if(type == "object" && val != null){
				if(val.constructor == Array){
					(val || []).forEach((subVal, indexArr) => {
						if(subVal != null){
							var type2 = typeof subVal;
							if(type == "object"){
								var subitemsString = createQueryString(subVal, ( prefix ? prefix+"["+key+"]["+indexArr+"]" : key+"["+indexArr+"]" ), includeNulls);
								items.push(subitemsString);
							}else if(type == "function"){
								console.log("=====> ignoring for subkey");
							}else{ 
								if(includeNulls || subVal != null){
									items.push( ( prefix ? prefix+"["+key+"]["+indexArr+"]" : key+"["+indexArr+"]" ) + "=" + encodeURI(subVal) );
								}
							}
						}
					});
				}else{
					var subitem = createQueryString(val, ( prefix ? prefix+"["+key+"]" : key ), includeNulls);
					items.push(subitem);
				}
			}else if(type == "function"){
				console.log("======> ignoring function "+key);
			}else{
				if(includeNulls || val != null){
					items.push( ( prefix ? prefix+"["+key+"]" : key )+"="+encodeURI(val) );
				}
			}
		}
	}

	return items.join("&");
};

export default {
	axios: api,
	baseURL: baseURL,
	baseURLImagen: baseURLImagen,
	getUrlImage: (path) => {
		return baseURLImagen + ((path.startsWith('/')||path.startsWith('\\')) ? '' : '/') + path;
	},
	getUrlDoc: (path) => {
		return baseURLImagen+ ((path.startsWith('/')||path.startsWith('\\')) ? '' : '/') + path;
	},
	url: (path, incluirToken, params) => {
		var append = '';
		if(incluirToken){
			if(path.indexOf('?') !== -1){
				append += '&access_token=' + session.authToken();
			}else{
				append += '?access_token=' + session.authToken();
			}
		}

		if(params){
			if (append.indexOf('?') === -1){
				append += "?";
			}

			Object.keys(params).forEach(key => {
				if(Array.isArray(params[key])){
					let arrayToQuery = createQueryString({[key]: params[key]})
					append += "&"+arrayToQuery;
				}else{
					append += "&" + key + "=" + params[key];
				}
			});

			append = encodeURI(append);
		}
		return baseURL + path + append;
	},
	post: function(path, data, isMultipart, params){
		return new Promise((resolve, reject) => {
			if(data != null && isMultipart){
				var data2 = new FormData();
				
				for(var key in data){
					if(Array.isArray(data[key])){
						for(var i = 0; i< data[key].length; i++){
							if( data[key][i] instanceof File || data[key][i] instanceof Blob ){
								data2.append(key, data[key][i]);								
							}else{
								Object.keys(data[key][i]).forEach(key2 => {
									if (data[key][i][key2] && data[key][i][key2] instanceof Date){
										var strVal = moment(data[key][i][key2]).format('YYYY-MM-DD HH:mm:ss');
										data2.append([key + '[' + i + '][' + key2 + ']'], strVal);
									}else{
										
										if(Array.isArray(data[key])){
											data2.append(
												[key+'['+i+']['+key2+']'],
												JSON.stringify(data[key][i][key2])
											);
										}else{
											data2.append(
												[key+'['+i+']['+key2+']'],
												data[key][i][key2]
											);
										}
									}
								});
							}
						}
					}else{
						if (data[key] && data[key] instanceof Date){
							var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
							data2.append(key, strVal);
						}else{							
							data2.append(key, data[key]);
						}
					}
				}
				var config = {
					headers: getHeaders(),
					params: params ? params : {} 
				};

				config.headers['content-type'] = 'multipart/form-data';				
				// for (var key of data2.entries()) {
				// 	console.log(key[0] + ', ' + key[1]);
				// }
				post(baseURL+path, data2, config)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}else{
				api.post(path, data, { params: params ? params : {} })
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}
		});
	},
	encodeParams: (params) => {
		return createQueryString(params); //queryString.stringify(params, { arrayFormat: "brackets"});
	},
	get: function(path, params){
		return new Promise((resolve, reject) => {
			api.get(path, { params: params ? params: {} })
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
	getNoBloqueante: (path, params) => {
		return new Promise((resolve, reject) => {
			api_no_bloqueante.get(path, { params: params ? params: {} }).then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
	put: function(path, data, isMultipart){
		return new Promise((resolve, reject) => {
			if(data != null && isMultipart){
				var data2 = new FormData();
				for(var key in data){
					if(Array.isArray(data[key])){
						for(var i = 0; i< data[key].length; i++){
							if( data[key][i] instanceof File || data[key][i] instanceof Blob ){
								data2.append(key, data[key][i]);								
							}else{
								Object.keys(data[key][i]).forEach(key2 => {
									if (data[key][i][key2] && data[key][i][key2] instanceof Date) {
										var strVal = moment(data[key][i][key2]).format('YYYY-MM-DD HH:mm:ss');
										data2.append([key + '[' + i + '][' + key2 + ']'], strVal);
									} else {
										data2.append(
											[key + '[' + i + '][' + key2 + ']'],
											data[key][i][key2]
										);
									}
								});
							}
						}
					}else{
						if (data[key] && data[key] instanceof Date) {
							var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
							data2.append(key, strVal);
						} else {
							data2.append(key, data[key]);
						}
					}
				}
				
				var config = {
					headers: getHeaders(),
				};

				config.headers['content-type'] = 'multipart/form-data';

				put(baseURL+path, data2, config)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}else{
				api.put(path, data)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}
		});
	},
	delete: function(path, params){
		return new Promise((resolve, reject)=>{
			api.delete(path, params ? { data: params } : undefined)
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		})
	},
	getDataPagination: function(path, params){
		return new Promise((resolve, reject)=>{
			api.get(path, { params: params ? params: {} })
			.then(resource => {
				var resp = resource.data;
				if(resp.status){
					resp.data.pages = Math.ceil(parseFloat(resp.data.count || 0 ) / parseFloat(params.count || 10));
				}
				resolve(resp);
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
	getCatalogo: function(path, params){
		return new Promise((resolve, reject) => {
			api_catalogo.get(path, { params: params ? params: {} })
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
}