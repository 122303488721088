import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

if("serviceWorker" in navigator){
    if(firebase.messaging.isSupported()){
        const fcm = firebase.messaging();
        navigator.serviceWorker.register( process.env.PUBLIC_URL + '/firebase-messaging-sw.js').then(async (swRegistration) => {
            fcm.useServiceWorker(swRegistration);
            await fcm.getToken();
        }).catch((error) => {
            console.log(error);
        });
    }
}else{
    console.log("SERVICE WORKER NOT IN THE BROWSER")
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
