import { utils } from '../../utils';

const initialState = { 
    update_badge: '',
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    if(action.type == 'UPDATE_BADGE'){
        newState.update_badge = utils.randomString(10);
    }

    return newState;
};

export default reducer;