import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { toast } from '.';
import TimerMixin from 'react-timer-mixin';

class MySearchAutocomplete extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            value: props.value || '',
            items: props.items || [],
            loading: false,
        };

        this.delay = props.delay || 500; // ms
        this.selectedValue = props.selectedValue || null;
        this.lastTimeout = null;
        this.onSelect = this.onSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this);
        this.onClear = this.onClear.bind(this);
        this.cancelPreviousTimer = this.cancelPreviousTimer.bind(this);
    }

    componentWillReceiveProps(nextProps){
        var data = {
            value: nextProps.value || '',
            items: nextProps.items || [],
            loading: false,
        };
        this.selectedValue = nextProps.selectedValue || null;
        this.setState(data);
    }

    cancelPreviousTimer(){
        if(this.lastTimeout){
            TimerMixin.clearTimeout(this.lastTimeout)
        }
    }

    onSelect(value, item){
        console.log("========> on select");
        this.selectedValue = item;
        this.setState({
            value: value,
            items: [ item ]
        }, () => {
            if(this.props.onSelect){
                this.props.onSelect(this.selectedValue);
            }
        });
    }

    onChange(event){
        var value = event.target.value;
        this.setState({
            value: value
        }, () => {
            this.handleAutocompleteSearch();
        });
    }

    handleAutocompleteSearch(){
        this.cancelPreviousTimer();
        this.lastTimeout = TimerMixin.setTimeout(() =>{
            this.setState({
                loading: true,
            }, () => {
                this.props.autocompletePromise(this.state.value).then(resource => {
                    if(resource.meta.success){
                        return resource.data;
                    }else{
                        if(this.props.showToast == null || this.props.showToast){
                            toast.warning(resource.meta.message);
                        }
                    }
                }).catch(error => {
                    if(this.props.showToast == null || this.props.showToast){
                        toast.warning(this.props.errorMessage || 'Error al buscar');
                    }
                }).then((listado) => {
                    this.setState({
                        items: listado || [],
                        loading: false
                    });
                });
            })
        }, this.delay);
    }

    onClear(){
        this.selectedValue = null;
        this.setState({
            value: '',
            items: [],
        }, () => {
            if(this.props.onSelect){
                this.props.onSelect(this.selectedValue);
            }
        });
    }

    render() {
        return (
            <Autocomplete
                wrapperStyle={{
                    // display: 'block',
                    // zIndex: '9999 !important'
                }}
                inputProps={{
                    id: this.props.id || 'state-autocomplete',
                    placeholder: this.props.placeholder || 'Buscar',
                    className:'form-control '+(this.props.valid ? ' is-valid' : '' )+( this.props.invalid ? ' is-invalid ' : '' ),
                    // onBlur: this.props.onBlur,
                    autoComplete: 'none',
                    disabled: this.props.disabled || false,
                    onKeyPress: this.props.onKeyPress || undefined,
                    // onFocus: () => {
                    //     if (this.props.onFocus){
                    //         this.props.onFocus();
                    //     }
                    // }
                }}
                value={ this.state.value }
                items={ this.state.items }
                getItemValue={(item) => item[this.props.field || 'autocomplete_field'] }
                onSelect={ this.onSelect }
                onChange={ this.onChange  }
                renderMenu={(items, value, style) => {
                    return <div style={{ ...style, ...this.menuStyle, zIndex: '9999' }} children={items}/>
                }}
                autoHighlight={false}
                renderItem={(item, isHighlighted) => (
                    <div
                        style={
                            {
                                padding: '10px',
                                fontSize: '14px',
                                cursor: 'pointer',
                                border: 'solid thin #c1c1c1',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden', 
                                maxWidth: '600px',
                                whiteSpace: 'nowrap',
                                zIndex: '9999',
                            }
                        }
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item.id}
                    >{item[this.props.field || 'autocomplete_field']}</div>
                )}
                renderInput={
                    (props) => {
                        return <div className="input-group">
                            <input
                                {...props}
                                ></input>
                            <div className="input-group-append">
                                <button tabIndex={-1} disabled={this.state.loading || (this.props.disabled || false)} type="button" onClick={
                                    (e) => {
                                        this.onClear();
                                        // this.selectedValue = null;
                                        // this.setState({
                                        //     autocomplete_value: '',
                                        //     autocomplete_items: [],
                                        // });
                                    }
                                } className="btn btn-secondary"><i className={this.state.loading ? 'fa fa-spin fa-circle-o-notch' : 'fa fa-times '}></i> </button>
                                {
                                    this.props.extraBtn ? this.props.extraBtn : null
                                }
                            </div>
                        </div>
                    }
                }
            >
            </Autocomplete>
        );
    }
}

export default MySearchAutocomplete;