import React, { Component } from 'react'
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from 'reactstrap'
import toast from './toast'

class ModalPreviewDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    this.refIframe = React.createRef()
    this.imprimirArchivo = this.imprimirArchivo.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url && this.props.url) {
      setTimeout(() => {
        if (this.refIframe.current) {
          this.refIframe.current.src = this.props.url
        }
      }, 0)
    }
  }

  descargarArchivo() {
    const { url, title } = this.props;
    if (!url) {
      toast.error('No hay un archivo para descargar.');
      return;
    }

    try {
      const anchor = document.createElement('a');
      anchor.href = url;
      const filename = title || url.split('/').pop() || 'documento_descargado.pdf';
      anchor.setAttribute('download', filename);

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      toast.error('Error al descargar el archivo.');
    }
  }

  imprimirArchivo() {
    try {
      const iframe = this.refIframe.current
      const iframeWindow = iframe.contentWindow || iframe
      iframe.focus()
      iframeWindow.print()
    } catch (error) {
      toast.error('Error al imprimir directamente. Descargue el documento e imprima.')
    }
  }

  render() {
    return (
      <Modal
        backdrop='static'
        size='lg'
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.isOpen}
        toggle={this.props.onClose}
        className={'modal-info'}
      >
        <ModalHeader toggle={this.props.onClose}>{this.props.title || 'Ver Documento'}</ModalHeader>
        {this.state.loading && (
          <ModalBody>
            <div className='text-center'>
              <h5>Espere un momento por favor, se está generando el formato</h5>
              <i className='fa fa-spin fa-circle-o-notch'></i>
            </div>
          </ModalBody>
        )}
        <div className='mt-15 mb-15 mx-2'>
          <Button onClick={e => this.descargarArchivo()} type='button' color='primary'>
            {' '}
            <i className='fa fa-download'></i> Descargar formato
          </Button>{' '}
          <Button onClick={e => this.imprimirArchivo()} type='button' color='primary'>
            {' '}
            <i className='fa fa-print'></i> Imprimir formato
          </Button>
        </div>
        <iframe title='Formato' ref={this.refIframe} id='iframePDF' style={{ width: '100%', height: '500px' }} />
        <ModalFooter>
          <Button type='button' color='secondary' onClick={this.props.onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalPreviewDocument
