import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import {Table, Input, FormGroup, Button, Col, Row } from 'reactstrap';
import PaginateButtonGroup from './PaginateButtonGroup';

const classNameFilterValue = 'table-paginate-filter-on';

class TablePaginate extends Component {

    constructor(props){
        super(props);
        
        this.filter = null;
        this.sorting = null;
        this.focusFilter = null;

        var cols =  (this.props.cols || []).filter(item => { return item.active == null || item.active == true });
        // (this.props.cols || []).forEach((item, index) => {
        //     if(item.active == null || item.active == true){
        //         item.active = true;
        //         cols.push(item);
        //     }
        // });

        // sirve para mantener los filtros que se le pongan a la tabla
        if (props.lastQT){
            cols.forEach(col => {
                if (props.lastQT.filter && col.filter && col.filter.active && col.filter.id && props.lastQT.filter[col.filter.id]){
                    col.filter.value = props.lastQT.filter[col.filter.id];
                }
            });
        }

        this.state = {
            cols: (cols || []),
            data: this.props.data || {count: 0, rows: 0},
            messageNoResults: this.props.messageNoResults || 'No se han encontrado registros',
            page: this.props.page,
            count: this.props.count,
            loading: this.props.loading || false,
            filterable: this.props.cols.find((item)=> { return item.filter && item.filter.active; }) != null,
            filtrando: false,
            lastUpdate: false,

            sortableIndividual: false,
        };
        this.requestGetData = this.requestGetData.bind(this);
        this.handleSortingEvent = this.handleSortingEvent.bind(this);
        this.handleFilterEvent = this.handleFilterEvent.bind(this);
        this.backupFilterSorting = this.backupFilterSorting.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.getRowClass = props.rowClass || null;
        this.limpiarFiltros = this.limpiarFiltros.bind(this);
        this.getParams = this.getParams.bind(this);
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     console.log(this.state.lastUpdate);
    //     return !this.state.lastUpdate;
    // }

    componentWillReceiveProps(nextProps){
        // var newCols = nextProps.cols || [];
        var newCols =  (nextProps.cols || []).filter(item => { return item.active == null || item.active == true });

        this.backupFilterSorting(this.state.cols);
                
        if(this.filter != null && this.sorting != null && (this.filter.length > 0 || this.sorting.length > 0)){
            newCols.forEach(newCol => {
                if(newCol.filter){
                    var oldFilter = this.filter.find((item)=>{
                        return item.id == newCol.filter.id;
                    });

                    if(oldFilter){
                        newCol.filter.value = oldFilter.value;
                    }

                    if(this.focusFilter != null && this.focusFilter == newCol.filter.id){
                        newCol.focus = true;
                    }
                }

                if(newCol.sorting){
                    var oldSorting = this.sorting.find( (item) => {
                        return item.id == newCol.sorting.id;
                    });

                    if(oldSorting){
                        newCol.sorting.value = oldSorting.value;
                    }
                }
            });
        }

        this.filter = [];
        this.sorting = [];
        this.focusFilter = null;
        
        this.setState({
            cols: newCols,
            data: nextProps.data || {count: 0, rows: 0},
            messageNoResults: nextProps.messageNoResults || 'No se han encontrado registros',
            page: nextProps.page,
            count: nextProps.count,
            loading: false,
            filterable: nextProps.cols.find((item)=> { return item.filter && item.filter.active; }) != null,
            lastUpdate: true,
            sortableIndividual: nextProps.sortableIndividual || false,
        });
    }

    limpiarFiltros(){
        var cols = this.state.cols;
        cols.forEach(col => {
            if(col.filter && col.filter.active && col.filter.value){
                col.filter.value = '';
            }
        });

        this.setState({ page: 1, cols }, () => {
            this.requestGetData();
        });

        
    }

    backupFilterSorting(cols){
        this.sorting = [];
        this.filter  = [];
        cols.forEach(element => {
            if(element.sorting){
                this.sorting.push(element.sorting);
            }

            if(element.filter){
                this.filter.push(element.filter);
            }
        });
    }

    handleSortingEvent(event, index){
        var cols = this.state.cols;
        cols[index].sorting.value = cols[index].sorting.value == 'asc' ? 'desc' : 'asc';
        if(this.state.sortableIndividual){
            cols.forEach((item, idx2) => {
                if(idx2 != index && item.sorting){ item.sorting.value = '';  }
            })
        }
        cols.forEach((item, i)=>{
            if(item.sorting && i!=index) item.sorting.value = '';
        });
        this.setState({
            cols
        }, () => {
            this.requestGetData();
        });
    }

    handleFilterEvent(event, index){
        var cols = this.state.cols;
        cols[index].filter.value = event.target.value;
        this.focusFilter = event.target.name;
        this.setState({
            page: 1,
            cols,
        }, () => {
            this.requestGetData();
        });
    }

    onPageChange(page, pageSize){
        this.setState({
            page: page || 1,
            count: pageSize || 10,
        }, () => {
            this.requestGetData();
        })
    }

    getParams(){
        var params = {
            page: this.state.page || 1,
            count: this.state.count || 10,
            filter: {},
            sorting: {},
        };

        var filtrando = false;
        this.state.cols.forEach(col => {
            if (col.filter && col.filter.active && col.filter.id && col.filter.value) {
                filtrando = true;
                params.filter[col.filter.id] = col.filter.value;
            }

            if (col.sorting && col.sorting.active && col.sorting.id && col.sorting.value) {
                params.sorting[col.sorting.id] = col.sorting.value == 'asc' ? 'asc' : 'desc';
            }
        });


        params.url = function () {
            var paramsUrl = {
                page: this.page,
                count: this.count,
            };
            if (this.filter) {
                Object.keys(this.filter).map((key) => {
                    paramsUrl['filter[' + key + ']'] = this.filter[key];
                });
            }

            if (this.sorting) {
                Object.keys(this.sorting).map((key) => {
                    paramsUrl['sorting[' + key + ']'] = this.sorting[key];
                });
            }

            return paramsUrl;
        };

        return [params, filtrando];
    }

    requestGetData(){
        var [params, filtrando] = this.getParams();
        this.setState({
            filtrando: filtrando,
            loading: true,
            lastUpdate: false,
        }, () => {
            this.props.getData(Object.create(params), params)
        });
    }
    componentDidMount(){
        this.requestGetData();
    }

    render() {

        const FilterItem = (props) =>{
            var item = props.item;
            var index = props.index;
    
            if(item.filter.type == 'text'){
                return <DebounceInput className={'form-control ' + (item.filter.value ? ' ' + classNameFilterValue : '')} 
                    type="text"
                    autoComplete="off" 
                    autoFocus={ item.focus || false }
                    minLength={0} 
                    debounceTimeout={500}
                    placeholder={ item.filter.placeholder ? item.filter.placeholder : '' } 
                    value={ item.filter.value } 
                    onChange={ (e) => { this.handleFilterEvent(e, index) } } 
                    name={item.filter.id} 
                    id={item.filter.id}  />
            }else if(item.filter.type == 'select'){
                return <Input type="select"
                    id={item.filter.id}
                    name={item.filter.name}
                    className={(item.filter.value ? ' ' + classNameFilterValue : '')}
                    onChange={(e) => { this.handleFilterEvent(e, index) }}
                    value={item.filter.value}
                >
                    <option value="">{ item.filter.placeholder || 'Todos' }</option>
                    {
                        (item.filter.options || []).map((opt, indexOpt) => {
                            return <option key={indexOpt} value={opt.id}>
                                { opt.label }
                            </option>
                        })
                    }
                </Input>
            }else{
                return (
                    <span></span>
                );
            }
        }

        const createColStyle = (width, moreStyle) => {
            if(width){
                if(moreStyle){
                    moreStyle.width = width;
                    return moreStyle;
                }else{
                    return {
                        width: width
                    };
                }
            }
            return {};
        };

        return (
            <div>
                <Row className="mb-10">
                    <Col xs="12" sm="12" md="6" lg="6">
                        <strong>{'Listando ' + this.state.data.rows.length + ' de ' + this.state.data.count + ' Resultados'}</strong>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" className="text-right">
                        {this.state.filtrando ? <span className="text-success"><strong>Filtrando resultados</strong> <Button type="button" onClick={this.limpiarFiltros} size="sm" color="secondary"><i className="fa fa-eraser"></i> Limpiar filtros</Button> </span> : null}
                    </Col>
                </Row>
                <div className="table-responsive">
                    {/* {
                        this.state.loading ?
                        <div className="alert2 alert-info2 text-center mt-10 mb-10">
                            <strong><i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw"></i> Cargando datos, espere por favor</strong>
                        </div>
                        : null
                    } */}
                    
                    <table className={"table "+this.props.className+' table-bordered table-paginate'}>
                        {/* className="thead-dark" */}
                        <thead className="thead-light">
                            <tr>
                            {
                                this.state.cols.map( (item, index) => {
                                    return item.sorting && item.sorting.active ? 
                                    <th style={ createColStyle(item.width, item.style) } className={item.headerClassName ? item.headerClassName : '' } key={'h1_'+index} onClick={ (e) => { this.handleSortingEvent(e, index) } }>
                                        <b style={{cursor: 'pointer'}}>{ item.title }</b> 
                                        <i className={'fa fa-fw '+(item.sorting.value == 'asc' ? 'fa-sort-asc' : ( item.sorting.value == 'desc' ? 'fa-sort-desc' : 'fa-sort' ))}></i>
                                    </th>
                                    :
                                    <th  style={ createColStyle(item.width, item.style) } className={item.headerClassName ? item.headerClassName : ''} key={'h1_'+index}>
                                        {item.title}
                                    </th>
                                })
                            }
                            </tr>

                            {
                                this.state.filterable ? 
                                <tr>
                                    {
                                        this.state.cols.map((item, index) => {
                                            return <th key={'h2_'+index}  style={ createColStyle(item.width, item.style) }>
                                                {
                                                    item.filter && item.filter.active ? 
                                                        <FilterItem
                                                            item={item}
                                                            index={index}>
                                                        </FilterItem>
                                                    : null
                                                }
                                            </th>
                                            
                                        })
                                    }
                                </tr>
                                : null
                            }
                        </thead>
                        <tbody>
                            {
                                this.state.data.rows.map((item, index) => {
                                    return <tr key={'row_item_'+index} className={ this.getRowClass != null ? this.getRowClass(item, index) : '' }>
                                        {
                                            this.state.cols.map((itemCol, indexCol) => {
                                                return <td className={'text-sm '+(itemCol.className || '')} key={'row_item_col_'+index+'_'+indexCol}  style={ createColStyle(itemCol.width, itemCol.style) }>
                                                    { itemCol.renderTD(item, index) }
                                                </td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                            {
                                this.state.data.rows == 0 ? 
                                <tr>
                                    <td colSpan={this.state.cols.length} className="text-center" style={{fontSize: '14pt'}}>{ this.state.messageNoResults || 'No se encontraron resultados' }</td>
                                </tr> : null
                            }
                        </tbody>
                    </table>
                </div>
                <PaginateButtonGroup 
                    total={ this.state.data.count }
                    page={ this.state.page || 1 }
                    count={ this.state.count || 10 }
                    onChange={this.onPageChange}>
                </PaginateButtonGroup>
            </div>
        );
    }
}

export default TablePaginate;