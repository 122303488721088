import { utils } from '../../utils';

const initialState = { 
    id: '',
    tipo: '',
    accion: '',
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    if (action.type == 'UPDATE_BADGE_MENU') {
        newState.id = action.id;
        newState.tipo = action.tipo;
        newState.accion = action.accion;
        return newState;
    }

    return null;
};

export default reducer;