import React, { Component } from 'react';
import { Input, InputGroup, InputGroupAddon, FormFeedback, InputGroupText, Button } from 'reactstrap';
import { api, toast } from '../utils';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class InputGroupFile extends Component {

    constructor(props) {
        super(props);
        this.inputTouched = false;
        this.file = undefined;
        this.maxFileSizeByte = props.maxFileSizeByte && props.maxFileSizeByte > 0 ? props.maxFileSizeByte : 4*1024*1024; // 4MB
        this.fileUploader = React.createRef();
        this.refInputText = React.createRef();
        this.createPropsStateObj = this.createPropsStateObj.bind(this);
        this.handleChanges = this.handleChanges.bind(this);
        this.openChooseDialog = this.openChooseDialog.bind(this);
        this.showPreview = this.showPreview.bind(this);
        this.state = this.createPropsStateObj(props);
        this.clearInput = this.clearInput.bind(this);
        this.triggerOnChange = this.triggerOnChange.bind(this);
    }

    clearInput(){
        this.file = null;
        this.setState({
            value: '',
            file: undefined,
        }, () => {
            console.log(this.fileUploader);
            this.fileUploader.current.value = "";
            this.props.onFileSelected(null, this.state.name);
            this.triggerOnChange('');
        })
    }

    openChooseDialog(){
        if(this.fileUploader.current){
            this.fileUploader.current.click();
        }
    }

    showPreview(){
        var uriFile = '';
        if(this.file){
            uriFile = URL.createObjectURL(this.file);
        }else if(this.state.value){
            uriFile = api.baseURLImagen + ((this.state.value.charAt(0) == '/' || this.state.value.charAt(0) == '\\') ? this.state.value : '/'+this.state.value);
        }else{
            toast.info('No se ha seleccionado ningún archivo');
        }
        if(uriFile){
            this.setState({
                uri_file: uriFile,
                preview_visible: true,
            });
        }
    }

    triggerOnChange(value){
        var input = null;
        if(this.props.id){
            console.log("=========> prop id. "+this.props.id)
            input = document.getElementById(this.props.id);
        }else if(this.props.name){
            console.log("=========> prop name. "+this.props.name)
            input = document.getElementsByName(this.props.name);
        }

        if(input){
            console.log("=======> found");
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
            nativeInputValueSetter.call(input, value != null ? value : this.state.value);
            var ev2 = new Event('input', { bubbles: true});
            input.dispatchEvent(ev2);
            this.inputTouched = true;
        }else{
            console.log("=======> NOO found");
        }
    }

    handleChanges(event){
        event.stopPropagation();
        event.preventDefault();
        this.file = event.target.files && event.target.files.length > 0 ? event.target.files[0] : undefined;
        if(this.file){
            if(this.file.size > this.maxFileSizeByte){
                var maxfs = parseFloat((this.maxFileSizeByte/1024.0)/1024.0).toFixed(2);
                toast.warning('El archivo seleccionado es demasiado grande, máximo '+(maxfs)+(maxfs >= 1 ? 'MB' : 'KB'));
                this.clearInput();
                return;
            }
        }

        this.setState({
            value: this.file ? this.file.name : ''
        }, () => {
            if(this.file && this.props.onFileSelected){
                this.props.onFileSelected(this.file, this.state.name);
            }

            this.triggerOnChange(this.state.value);
        });
    }

    componentWillReceiveProps(nextProps){
        this.setState(this.createPropsStateObj(nextProps));
    }

    createPropsStateObj(props){
        if(props.file){
            this.file = props.file;
        }
        var vals = {
            preview_visible: false,
            uri_file: '',
            file: props.file || undefined,
            name: props.name,
            id: props.id,
            placeholder: props.placeholder || '',
            required: props.required || false,
            valid: props.valid,
            invalid: props.invalid,
            disableBtnSelect: props.disableBtnSelect,
            disableBtnPreview: props.disableBtnPreview,
        };

        if(!this.inputTouched){
            vals.value = props.value || '';
        }
        return vals;
    }

    render() {
        return (
            <div>
                <InputGroup>
                    <input accept={this.props.accept ? this.props.accept : null } className="d-none" type="file" ref={this.fileUploader} name={this.state.name+'_file'} id={this.state.id+'_file'} onChange={this.handleChanges}/>
                    <Input 
                        ref={this.refInputText}
                        valid={this.state.valid} 
                        invalid={this.state.invalid} 
                        type="text"
                        name={this.state.name} 
                        id={this.state.id}
                        required={this.state.required}
                        disabled 
                        placeholder={this.state.placeholder || 'Seleccione'} 
                        value={this.state.value}
                        onChange={ (e) => {
                            console.log("=======> onChange");
                            console.log(e);
                            if(this.props.onChange){
                                this.props.onChange(e);
                            }
                        }}
                        onBlur={ () => {
                            // console.log("=======> onBlur");
                            if(this.props.onBlur){
                                this.props.onBlur();
                            }
                        }}
                         readOnly />
                    <InputGroupAddon addonType="append">
                        <Button disabled={this.state.disableBtnSelect} type="button" onClick={this.openChooseDialog} color="dark" outline><i className="fa fa-file-archive-o"></i></Button>
                        <Button disabled={this.state.disableBtnSelect} type="button" onClick={this.clearInput} color="dark" outline><i className="fa fa-times"></i></Button>
                    </InputGroupAddon>
                    {
                        this.state.name ?
                        <InputGroupAddon addonType="append">
                            <Button disabled={this.state.disableBtnPreview} type="button" onClick={this.showPreview} color="dark" outline><i className="fa fa-eye"></i></Button>
                        </InputGroupAddon> 
                        : null
                    }
                    <FormFeedback>{this.props.feedback}</FormFeedback>
                </InputGroup>
                <Viewer
                    visible={this.state.preview_visible}
                    zIndex="999999"
                    drag="false"
                    onClose={() => { this.setState({ preview_visible: false }); } }
                    images={[{src: this.state.uri_file, alt: 'Imagen seleccionada'}]} />
            </div>
        );
    }
}

export default InputGroupFile;