import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';

var Validation = {
    validators: {
        email: {
            errorMessage: 'El correo ingresado es inválido',
            fn: (value) => {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
        },        
        number: {
            errorMessage: 'El número ingresado es inválido',
            fn: (value) => {
                return parseInt(value) != NaN;
            }
        },
        regex: {
            errorMessage: 'El valor ingresado no cumple con el formato requerido',
            fn: (value, regex) => {
                return regex.test(String(value).toLowerCase());
            }
        },
        date: {
            errorMessage: 'La fecha ingresada es inválida',
            fn: (value) => {
                return moment(value).isValid();
            }
        },
        datetime: {
            errorMessage: 'La fecha ingresada es inválida',
            fn: (value) => {
                return moment(value).isValid();
            }
        },
        time: {
            errorMessage: 'El tiempo ingresado es inválido',
            fn: (value) => {
                return moment(value).isValid();
            }
        },
        float: {
            errorMessage: 'El número ingresado es inválido',
            fn: (value) => {
                return parseFloat(value) != NaN;
            }
        },        
        rfc: {
            errorMessage: 'El rfc ingresado es inválido',
            fn: (value) => {
                var re = /^([A-Z|a-z|&amp;]{3}\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)|([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$|^([A-Z|a-z]{4}\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)|([02468][048]|[13579][26])0229)((\w{2})([A|a|0-9]{1})){0,3}$/;
                return re.test(String(value).toLowerCase());
            },
        },
    },
    addValidation(newValidationName, errorMessage, newValidationFunction){
        Validation.validators[newValidationName] = newValidationFunction;
    },
    create(type, required, options){
        return {
            type: type,
            required: required,
            pristine: true,
            invalid: false,
            valid: false,
            dirty: false,
            validate: options ? (options.validate || []) : [],
        };
    },
    isValidItem(validationObject, value, forSubmit){
        var r = Validation.isValidInput(validationObject, value, forSubmit);
        validationObject.invalid = r.invalid;
        validationObject.valid = r.valid;
        validationObject.errorMessage = r.invalid ? r.errorMessage : null;
        validationObject.pristine = false;
        validationObject.dirty = true;

        return validationObject;
    },
    isValidInput(validationObject, value, forSubmit){
        var errorMessage = [];
        var isValid = true;
        var isInValid = false;

        // validar requerido
        if(validationObject.required){
            if(validationObject.pristine && !forSubmit){
                isInValid = false;
                isValid = value ? true : false;
            }else{
                isInValid = value ? false : true;
                isValid = value ? true : false;
            }

            if(isInValid && !isValid){
                errorMessage.push('Este campo es requerido');
            }
        }
        
        if(value){// && (!validationObject.pristine || forSubmit) ){ // aqui debemos validar su tipo de dato y si tiene validadores extras
            if(validationObject.type){
                // validar tipo
                if( Validation.validators[validationObject.type] != null){
                    if(! Validation.validators[validationObject.type].fn(value) ){
                        isInValid = true;
                        isValid = false;
                        errorMessage.push(Validation.validators[validationObject.type].errorMessage || 'El valor es inválido');
                    }
                }

                // validar extras
                if(validationObject.validate && validationObject.validate.length > 0){
                    validationObject.validate.forEach(valName => {
                        if( Validation.validators[valName] != null ){
                            if(! Validation.validators[valName].fn(value) ){
                                errorMessage.push(Validation.validators[valName].errorMessage || 'El valor es inválido');
                                isInValid = true;
                                isValid = false;
                            }
                        }
                    });
                }
            }
        }

        return {
            invalid: isInValid,
            valid: isValid,
            errorMessage: errorMessage.join('. ')
        };
    },
    isValidForm(validation, data){
        var validation2 = {};
        var isValid = true;
        Object.keys(validation).forEach( (key) => {
            var validationItem = validation[key];
            if(validationItem){
                validationItem = Validation.isValidItem(validationItem, data[key], true);
                isValid = isValid && validationItem.valid;
                validation2[key] = validationItem;
            }
        });

        return {
            isValid: isValid,
            validation: validation2
        };
    }
};

export default Validation;