import * as firebase from 'firebase/app';
import 'firebase/messaging';
import config from '../config'

var messaging = null;
if(firebase.messaging.isSupported()){
    const fcmConfig = config.fcm

    const initializedFirebaseApp = firebase.initializeApp(fcmConfig);
    
    // initializedFirebaseApp.analytics();
    
    messaging = initializedFirebaseApp.messaging();
    
    
    // Project Settings => Cloud Messaging => Web Push certificates
    messaging.usePublicVapidKey(config.fcm_web_push_certificate);
}

export { messaging };